.idp:hover {
 color: #007bff;
}

.idp {
    color: #495057;
}

#login-submit:hover {
    background-color: #ffec9c;
}