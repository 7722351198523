body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.greyRow{
  background-color: 	#F8F8F8;
}
.hasMoreHoursRow
{
  background-color: 		#FFE4E1;
}
.inputSumHasMoreHours{
  background-color: #FFE4E1 !important;
}
.updateBtn:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  background-color: #28A745 !important;
}
.switch-wrapper > input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch-wrapper > input[type="checkbox"] + .switch {
  transform: translateX(5px);
}
.switch-wrapper > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-19px);
}
.switch-wrapper > input[type="checkbox"]:disabled + .switch {
  background: #ccc;
}

.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 48px;
}

.switch-wrapper > .switch {
  color: #fff;
  display: inline-block;
  height: 100%;
  left: -100%;
  position: relative;
  transition: 100ms linear;
  width: 200%;
}
.switch-wrapper > .switch > .switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  left: 50%;
  position: relative;
  top: 1px;
  width: 14px;
  z-index: 3;
}

.switch-label {
  float: left;
  margin-left: 10px;
}
input:focus{
  outline: 0;
}
.inputWorkingDay{
  background-color: #FFC107;
  border: none !important;
  border-radius: 5px;
}
.inputSum{
  background-color: 	#F8F8F8 !important;
}
.sumHours{
 border-bottom: 2px solid #FFC107;

}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}