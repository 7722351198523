.nav {
    display: flex;
    background: white;
    justify-content: space-between;
    align-items: center;
    /*padding: 0px 40px;*/
    height: 50px;
    box-shadow: 0px 10px 15px rgba(133, 133, 133, 0.05);
}
.logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo {
    width: 45px;
    height: 45px;
}
.nav-options {
    padding-left: 25px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 50px;
    list-style-type: none;
}
.mobile-option {
    display: none;
}
.mobile-menu {
    display: none;
}

.option :hover {
    color: white;
}

.title-nav{
    font-size: 20px;
    padding: 0 10px;
    color: #000;
    font-weight: bold;
}
