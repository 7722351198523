.inputForm{
    border-radius: 8px;
    box-shadow: #4c4c4c;
    /*width: 500px;*/
    /*height: 40px;*/
}

.saveBtn{
    background: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
    border: #fece00;
    color: black;
    width: 500px;
    border-radius: 8px;
}
.addMemberBtn{
    background: linear-gradient(0deg, #0E9A36 0%, #068c28 100%);
    border: #0E9A36;
    color: white;
    width: 300px!important;
    border-radius: 8px;
}
.addMemberBtn:hover{
    color: white;
}
.negativeBtn{
    background: #fbf4cd;
    border: #fbf4cd;
    color: black;
    width: 500px;
    border-radius: 8px;
    margin-top: 5px;
}
.dangerBtn{
    background: orangered;
    border: orangered;
    color: white;
    width: 500px;
    border-radius: 8px;
}

.dangerCancelBtn{
    border: 1px solid orangered;
    background: white;

    color: orangered;
    width: 500px;
    border-radius: 8px;
}
.infoBtn{
    background: #fece00;
    border: #fece00;
    color: black;
    border-radius: 8px;
    margin-left: 5px;
    margin-bottom: 2px;
    width: 117px;
}
.mainCol{
    display: flex;
    align-items: center;
    justify-content: center;
}
.formMember{
    width: 500px;
}

.mainRow{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    margin-bottom: 10px;
}

.formHeader{
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    display: flex;

}

.tableCustom{
    border-radius: 8px;
}

.customCard{
    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0px 10px 15px rgba(133, 133, 133, 0.05);
    border-radius: 8px;
    width: 100%;
    margin-bottom: 10px;

}
.customCardTimesheets{
    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0px 10px 15px rgba(133, 133, 133, 0.05);
    border-radius: 8px;
    width: 100%;
    margin-bottom: 10px;
}
.buttonIcon{
    pointer-events:none;
}
.MuiCardHeader-title{
    font-weight: bold!important;
}

.table {
    border-collapse:collapse;
}
.table thead th {
    border-bottom:gray;
    border-top: none!important;
    text-align: left;
    padding: 8px;
}

.filterField{
    border-left: transparent;
    border-top: transparent;
    border-right: transparent;
}
.filterField:focus{
    outline: none;
}

.menuSelect{
    border: transparent;
}
.menuSelect:focus{
    outline: none;
}

.tableCellInput{
    width: 30px;
    border-left: transparent;
    border-top: transparent;
    border-right: transparent;
}
.tableCellInputSum{
    width: 30px;
    border-left: transparent;
    border-top: transparent;
    border-right: transparent;
}



.tableCellInput:focus{
    outline: none;
    background: #f2e441;
    border-bottom: transparent;
    border-radius: 3px;
}

@media (max-width: 320px) and (max-width: 480px){
    .card{
        margin-left: 5%!important;
    }
    .mainCol{
        margin-left: 65px!important;
    }
    .saveBtn{
        width: 200px!important;
    }
    .addMemberBtn{
        width: 200px!important;
        margin-left: 2%!important;
    }
    .dangerBtn{
        width: 200px!important;
    }
}
@media(max-width: 481px) and (max-width: 767px){
    .card{
        margin-left: 5%!important;
        overflow-y: auto!important;
        height: 520px!important;
    }
    .mainCol{
        margin-left: 60px!important;
    }
    .saveBtn{
        width: 200px!important;
        margin-left: 2%!important;
    }
    .addMemberBtn{
        width: 200px!important;
    }
    .dangerBtn{
        width: 200px!important;
    }
}
@media(max-width: 768px) and (max-width: 1024px){
    .card{
        overflow-y: auto!important;
        height: 520px!important;

    }
    .mainCol{
        margin-left: 60px!important;
    }
    .saveBtn{
        width: 200px!important;
    }
    .addMemberBtn{
        width: 200px!important;
    }
    .dangerBtn{
        width: 200px!important;
    }

}
@media(max-width: 1025px) and (max-width:1200px) {
    .card{
        overflow-y: auto!important;
        height: 520px!important;

    }
}


.projectUl {
    list-style: none;
}

.projectUl .projectLi::before {
    content: "\2022";
    color: #fece00;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    margin-left: -1em;
}

.projectManagerCell{
    width: 1%;
    white-space: nowrap;
}